import { IMatrixGalleryCorvidModel } from '../MatrixGallery.types';

const entry: IMatrixGalleryCorvidModel = {
  componentType: 'MatrixGallery',
  sdkType: 'Gallery',
  loadSDK: () =>
    import(
      './MatrixGallery.corvid' /* webpackChunkName: "MatrixGallery.corvid" */
    ),
};

export default entry;
