import { ISeoTpaGalleryCorvidModel } from '../SeoTpaGallery.types';

const entry: ISeoTpaGalleryCorvidModel = {
  componentType: 'SeoTpaGallery',
  loadSDK: () =>
    import(
      './SeoTpaGallery.corvid' /* webpackChunkName: "SeoTpaGallery.corvid" */
    ),
};

export default entry;
