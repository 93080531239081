import { ISliderGalleryCorvidModel } from '../SliderGallery.types';

const entry: ISliderGalleryCorvidModel = {
  componentType: 'SliderGallery',
  sdkType: 'Gallery',
  loadSDK: () =>
    import(
      './SliderGallery.corvid' /* webpackChunkName: "SliderGallery.corvid" */
    ),
};

export default entry;
