import { ISiteButtonCorvidModel as ImageButtonCorvidModel } from '../../SiteButton/SiteButton.types';

const entry: ImageButtonCorvidModel = {
  componentType: 'ImageButton',
  sdkType: 'Button',
  loadSDK: () =>
    import('./ImageButton.corvid' /* webpackChunkName: "ImageButton.corvid" */),
};

export default entry;
