import { IPaginatedGridGalleryCorvidModel } from '../PaginatedGridGallery.types';

const entry: IPaginatedGridGalleryCorvidModel = {
  componentType: 'PaginatedGridGallery',
  sdkType: 'Gallery',
  loadSDK: () =>
    import(
      './PaginatedGridGallery.corvid' /* webpackChunkName: "PaginatedGridGallery.corvid" */
    ),
};

export default entry;
