import { IHoverBoxCorvidModel } from '../HoverBox.types';

const entry: IHoverBoxCorvidModel = {
  componentType: 'HoverBox',
  sdkType: 'Container',
  loadSDK: () =>
    import('./HoverBox.corvid' /* webpackChunkName: "HoverBox.corvid" */),
};

export default entry;
