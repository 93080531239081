import { TPA3DGalleryCorvidModel } from '../TPA3DGallery.types';

const entry: TPA3DGalleryCorvidModel = {
  componentType: 'TPA3DGallery',
  sdkType: 'Gallery',
  loadSDK: () =>
    import(
      './TPA3DGallery.corvid' /* webpackChunkName: "TPA3DGallery.corvid" */
    ),
};

export default entry;
