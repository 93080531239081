import { ISignatureInputCorvidModel } from '../SignatureInput.types';

const entry: ISignatureInputCorvidModel = {
  componentType: 'SignatureInput',
  loadSDK: () =>
    import(
      './SignatureInput.corvid' /* webpackChunkName: "SignatureInput.corvid" */
    ),
};

export default entry;
