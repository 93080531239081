import { IContainerCorvidModel } from '../../Container/Container.types';

const entry: IContainerCorvidModel = {
  componentType: 'Group',
  sdkType: 'Box',
  loadSDK: () =>
    import('./Group.corvid' /* webpackChunkName: "Group.corvid" */),
};

export default entry;
