import { IMediaContainerCorvidModel } from '../../MediaContainers/MediaContainer/MediaContainer.types';
import { componentType } from '../constants';

const entry: IMediaContainerCorvidModel = {
  componentType,
  sdkType: 'Column',
  loadSDK: () =>
    import('./Column.corvid' /* webpackChunkName: "Column.corvid" */),
};

export default entry;
