import { TPA3DCarouselCorvidModel } from '../TPA3DCarousel.types';

const entry: TPA3DCarouselCorvidModel = {
  componentType: 'TPA3DCarousel',
  sdkType: 'Gallery',
  loadSDK: () =>
    import(
      './TPA3DCarousel.corvid' /* webpackChunkName: "TPA3DCarousel.corvid" */
    ),
};

export default entry;
