import { IAddressInputCorvidModel } from '../AddressInput.types';

const entry: IAddressInputCorvidModel = {
  componentType: 'AddressInput',
  loadSDK: () =>
    import(
      './AddressInput.corvid' /* webpackChunkName: "AddressInput.corvid" */
    ),
};

export default entry;
