import { IStateBoxCorvidModel } from '../StateBox.types';

const entry: IStateBoxCorvidModel = {
  componentType: 'StateBox',
  sdkType: 'MultiStateBox',
  loadSDK: () =>
    import('./StateBox.corvid' /* webpackChunkName: "StateBox.corvid" */),
};

export default entry;
